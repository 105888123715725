




































import type { Link, Picture } from '@/inc/types'
import { debounce } from 'throttle-debounce'
import gsap from 'gsap'
import {
  PropType,
  defineComponent,
  ref,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'

import { pushCta, GtmCta, prefersReducedMotion } from '@/inc/utils'

interface CTAGallery {
  title: string
  htmltext: string
  link: Link
  icon?: string
  pictures: Picture[]
}

gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'CtaGallery',
  components: {},
  props: {
    content: {
      type: Object as PropType<CTAGallery>,
      required: true,
    },
  },

  setup(props) {
    const rootEl = ref<HTMLElement | null>(null)
    const itemsEl = ref<HTMLElement[]>([])
    const id = 'cta-gallery'
    const breakpoint = 1024
    let tl: gsap.core.Timeline
    let isMobile = true

    // Create timeline
    const initTimeline = () => {
      // Don't use ScrollTigger if the user prefers reduced motion
      if (!rootEl.value || prefersReducedMotion()) {
        return
      }

      if (tl) {
        tl.kill()
        ScrollTrigger?.getById(id)?.kill()
        gsap.set(itemsEl.value, { clearProps: 'all' })
      }

      tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: rootEl.value,
          scrub: 0.5,
          id,
          start: isMobile ? 'top 90%' : 'top bottom',
          end: isMobile ? 'bottom 50%' : 'bottom 50%',
        },
      })

      tl.from(itemsEl.value, {
        rotate: '+=10deg',
        y: isMobile ? '+=100' : '+=200',
      })
    }

    // Switch between desktop and mobile timelines
    const onResize = debounce(500, (width: number) => {
      isMobile = width < breakpoint

      initTimeline()
    })

    // Push event to datalayer on click
    const onClick = () => {
      if (!rootEl.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: props.content.title,
        ctaType: 'gallery',
        ctaUrl: props.content.link.url,
      }

      pushCta(layer, rootEl.value)
    }

    onMounted(() => {
      isMobile = window.innerWidth < breakpoint
      initTimeline()
    })

    onBeforeUnmount(() => {
      // Destroy ScrollTrigger instance
      ScrollTrigger?.getById(id)?.kill()
    })

    return {
      rootEl,
      itemsEl,
      onResize,
      onClick,
    }
  },
})
